(function ($) {

  $(function () {
    $('.ganban-studio-slide').slick({
      infinite: true,
      dots: false,
      arrows: true,
      autoplay: false,
      centerMode: true,
      centerPadding: '15%',
      responsive: [
        {
          breakpoint: 768,
          settings: {
            centerPadding: '20px',
          },
        },
      ],
    });
  });

  $(function () {
    $('.column-list').slick({
      infinite: true,
      slidesToShow: 1,
      dots: false,
      arrows: true,
      autoplay: false,
      centerMode: true,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 1,
            centerPadding: '20px',
            variableWidth: false,
          },
        },
      ],
    });
  });

})(jQuery);